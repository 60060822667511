import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import '../theme/bootstrap-grid.css'
import {
  Box,
  Card,
  Container,
  Flex,
  Heading,
  Image,
  Layout,
  Text
} from '../components'

interface ShippingReceivingTemplateProps {
  seoTitle: string
  metaDescription: string
  alert?: string
  intro: {
    heading: string
    subheading: string
    text: string
    image: string
  }
  hours: {
    heading: string
    departments: Array<{
      text: string
    }>
    text: string
  }
  strengths: Array<{
    heading: string
    text: string
    icon: string
  }>
}

const ShippingReceivingPageTemplate: React.FC<ShippingReceivingTemplateProps> = ({
  seoTitle,
  metaDescription,
  alert,
  intro,
  hours,
  strengths
}) => {
  return (
    <>
      <Helmet>
        <title>Incobrasa | {seoTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <Box py="100">
        <Container>
          {alert && (
            <Flex
              mt="-50"
              mb="50"
              py="medium"
              px="xlarge"
              alignItems="center"
              bg="rgba(243, 196, 68, 0.15)"
              borderRadius="8"
            >
              <Image
                src="/img/attention-icon.svg"
                alt="attention"
                width="36"
                mr="medium"
              />
              <Text size="small" weight="bold" textTransform="uppercase">
                {alert}
              </Text>
            </Flex>
          )}
          <Flex wrap="wrap">
            <Box width={[1, 1, 1 / 2]}>
              <Image
                src="/img/shipping-train.jpg"
                alt="shipping"
                borderRadius="30"
              />
            </Box>
            <Box
              width={[1, 1, 1 / 2]}
              pl={['none', 'none', 'large']}
              mt={['xlarge', 'xlarge', 'large', 'xxlarge']}
            >
              <Heading level="2" mb="large" textTransform="uppercase">
                {intro.heading}
              </Heading>
              <Text size="small" whiteSpace="break-spaces">
                {intro.text}
              </Text>
            </Box>
          </Flex>
        </Container>
      </Box>
      <Box bg="lime" py="50">
        <Container>
          <Heading level="2" mb="xlarge" textTransform="uppercase">
            {hours.heading}
          </Heading>
          {hours.departments.map(({ text }) => (
            <Text key={text} size="small" mb="small">
              {text}
            </Text>
          ))}
          <Text size="small" mt="xlarge">
            {hours.text}
          </Text>
        </Container>
      </Box>
      <Box mb="100" position="relative">
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          height="80"
          bg="lime"
        />
        <Container wide>
          <Box className="row" position="relative">
            {strengths.map(({ heading, icon, text }) => (
              <Box key={heading} className="col col-12 col-md-4" mb="medium">
                <Card>
                  <Image src={icon} alt={icon} width="50" mb="large" />
                  <Heading level="4" mb="medium" textTransform="uppercase">
                    {heading}
                  </Heading>
                  <Text size="small" whiteSpace="break-spaces">
                    {text}
                  </Text>
                </Card>
              </Box>
            ))}
          </Box>
        </Container>
      </Box>
    </>
  )
}

interface ShippingReceivingPageProps {
  data: {
    markdownRemark: {
      frontmatter: ShippingReceivingTemplateProps
    }
  }
}

const ShippingReceivingPage: React.FC<ShippingReceivingPageProps> = ({
  data
}) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <ShippingReceivingPageTemplate {...frontmatter} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ShippingReceivingPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "shipping-receiving" } }) {
      frontmatter {
        seoTitle
        metaDescription
        alert
        intro {
          heading
          subheading
          text
          image
        }
        hours {
          heading
          text
          departments {
            text
          }
        }
        strengths {
          heading
          text
          icon
        }
      }
    }
  }
`

export { ShippingReceivingPageTemplate }
export default ShippingReceivingPage
